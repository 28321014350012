import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)


const features = [
    {
        name: 'Oasis de Tranquilidad',
        description:
            'Un refugio dentro del ajetreo del trabajo, donde el silencio y la paz reinan. Un espacio con una atmósfera serena y libre de distracciones, ideal para concentrarse y realizar tareas con máxima eficiencia.',
        icon: MusicalNoteIcon,
    },
    {
        name: 'Arte que Inspira',
        description: 'Un espacio de trabajo que no solo sea funcional, sino también un festín para los ojos. Murales vibrantes y llenos de color',
        icon: PaintBrushIcon,
    },
    {
        name: 'Un oasis verde dentro de la oficina, donde puedes conectar con la naturaleza y disfrutar de sus beneficios',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
        icon: GlobeAmericasIcon,
    },
]


export default function FeatureSectionCoyoacan() {

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#textBuga',
                start: '-40% center',
                end: '100% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tl.to('#textBuga', {
            x: 0,
            duration: 3
        });

        let tlf = gsap.timeline({
            scrollTrigger: {
                trigger: '.featureB',
                start: '-60% 70%',
                end: '90% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tlf.to('.featureB', {
            x: 0,
            duration: 3
        });

        let tli = gsap.timeline({
            scrollTrigger: {
                trigger: '#imgBuga',
                start: '30% 50%',
                end: '200% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tli.to('#imgBuga', {
            x: 0,
            duration: 3
        });



    }, []);
    return (
        <div className="overflow-hidden py-24 sm:py-32 bg-gradient-to-b3" id='campusBuga'>
            <div className="mx-auto max-w-7xl lg:px-8">
                <div className="relative isolate px-5 pt-14 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <h2 className="text-base font-semibold leading-7 text-indigo-600">CDMX</h2>
                                <Sparkles children={<p className="mt-2 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl mb-10">Coyoacán</p>
                                } />

                                <div className="mt-6 text-lg leading-8 text-purple-50 translate-x-[-125%]" id='textBuga'>
                                    <MapsTemplate campus={"coyoacan"} />
                                </div>

                            </div>
                        </div>
                        <div className='relative translate-x-[125%] w-[48rem] h-[40rem]'  id='imgBuga'>
                            <img
                                src={require("../../../static/images/larghettoCoyoacan.jpg")}
                                alt="Product screenshot"
                                className="w-[48rem] h-[40rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                width={2432}
                                height={1442}

                            />
                            <Link to={"/coyoacan"} className='absolute top-3 left-3 w-24 h-12 bg-gray-900 text-gray-300 flex items-center justify-center rounded-lg font-semibold hover:border-solid hover:border-opacity-10 hover:border-2 cursor-pointer hover:text-white hover:font-md'>Saber más</Link>
                        </div>
                        <div id='glowcircle' className='circlePosition w-[600px] h-[400px] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 rounded-[100%] fixed z-1  left-[20%] translate-x-[-50%] translate-y-[-50%] blur-[100px]'></div>
                        <div
                            className="absolute inset-x-0 -top-10 -top[50%] left-[0%] -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 10% 30.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                        <div
                            className="absolute inset-x-0 top-[50%] left-[-120%] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
