import './App.css';
import AppRouter from './shared/AppRouter';
import React, { useEffect } from 'react';


function App() {
  
  //Agregar titulo, descripción y keywords
  useEffect(() => {
    document.title = "Larghetto | Academia de Música";
    document.querySelector('meta[name="description"]').setAttribute("content", "Descubre tu potencial musical en nuestra academia en Morelos con campus en Bugambilias, Centro y Cuautla. Clases para niños, adolescentes y adultos. Aprende piano, guitarra, violín y más. ¡Únete y desata tu creatividad hoy!");

    const keywords = "academia de música, Morelos, Bugambilias, Centro, Cuautla, clases de música, niños, adolescentes, adultos, piano, guitarra, violín";
    const metaKeywords = document.createElement('meta');
    metaKeywords.name = 'keywords';
    metaKeywords.content = keywords;
    document.head.appendChild(metaKeywords);
    document.documentElement.lang = "es";
  }, []);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
  
}

export default App;
