/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
export default function AboutThree() {
    return (
        <div className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-baseline sm:justify-between">
                    <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Experiencias</h2>
                    <a href="https://www.facebook.com/LarghettoRMA/photos_albums?locale=es_LA" className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block">
              Ver todas las experiencias<span aria-hidden="true"> &rarr;</span>
            </a>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
                    <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2">
                        <img
                            src={require("../../../static/images/LarghettoConciertoFin2021.jpg")}
                            alt="Concierto de Fin de Año"
                            className="object-center object-cover group-hover:opacity-75"
                        />
                        <div aria-hidden="true" className="bg-gradient-to-b from-transparent to-black opacity-50" />
                        <div className="p-6 flex items-end">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <a href="https://www.facebook.com/media/set/?set=a.6703094446427954&type=3&locale=es_LA">
                                        <span className="absolute inset-0" />
                                        Concierto de Fin de Año
                                    </a>
                                </h3>
                                <p aria-hidden="true" className="mt-1 text-sm text-white justify-start flex">
                                    <div>
                                        2021
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
                        <img
                            src={require("../../../static/images/ConciertoVivoXElRock2022.jpg")}
                            alt="Concierto Vivo X El Rock 😎🔥🎶"
                            className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                        />
                        <div className="p-6 flex items-end sm:absolute sm:inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <a href="https://www.facebook.com/media/set/?set=a.7135116486559079&type=3&locale=es_LA">
                                        <span className="absolute inset-0" />
                                        Concierto "Vivo X El Rock 😎🔥🎶"
                                    </a>
                                </h3>
                                <p aria-hidden="true" className="mt-1 text-sm text-white flex justify-start">
                                    2022
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden sm:relative sm:aspect-none sm:h-full">
                        <img
                            src={require("../../../static/images/ConciertoFrankDiPaulino.jpg")}
                            alt="Concierto Frank Di & Paulino Monroy"
                            className="object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full"
                        />
                        <div
                            aria-hidden="true"
                            className="bg-gradient-to-b from-transparent to-black opacity-50 sm:absolute sm:inset-0"
                        />
                        <div className="p-6 flex items-end sm:absolute sm:inset-0">
                            <div>
                                <h3 className="font-semibold text-white">
                                    <a href="https://www.facebook.com/media/set/?set=a.1761222913948490&type=3&locale=es_LA">
                                        <span className="absolute inset-0" />
                                        Concierto Frank Di & Paulino Monroy
                                    </a>
                                </h3>
                                <p aria-hidden="true" className="mt-1 text-sm text-white flex justify-start">
                                    2017
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-6 sm:hidden">
                    <a href="https://www.facebook.com/LarghettoRMA" className="block text-sm font-semibold text-indigo-600 hover:text-indigo-500">
                        Ver todas las experiencias<span aria-hidden="true"> &rarr;</span>
                    </a>
                </div>
            </div>
        </div>
    )
}
