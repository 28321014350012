import { SparklesIcon, ClockIcon, BuildingOffice2Icon } from '@heroicons/react/20/solid'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import Sparkles from '../../animatedLanding/components/Sparkle';
import { Link } from 'react-router-dom';
import MapsTemplate from '../../../shared/MapsTemplate';
gsap.registerPlugin(ScrollTrigger)

const features = [
  {
    name: 'Corazón de la ciudad',
    description:
      'Sumérgete en el epicentro musical de la ciudad. La Colonia Centro te coloca a pasos de escuelas de música, tiendas de instrumentos, salas de conciertos y eventos musicales.',
    icon: BuildingOffice2Icon,
  },
  {
    name: ' Tu ritmo, tu horario',
    description: 'La Colonia Centro se adapta a tu estilo de vida musical. Encuentra diversidad de horarios en clases, ensayos y presentaciones, para que puedas disfrutar de la música sin restricciones',
    icon: ClockIcon,
  },
  {
    name: 'Estacionamiento sin contratiempos',
    description: 'Olvídate de las preocupaciones por el estacionamiento. La Colonia Centro te ofrece opciones de aparcamiento cercanas para que llegues a tus clases con tranquilidad.',
    icon: SparklesIcon,
  },
]

export default function FeatureSectionCentro() {

  useEffect(() => {
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: '#textCentro',
        start: '-40% center',
        end: '100% center',
        scrub: true,
        markers: false,
        toggleActions: 'play reverse play reverse'
      }
    });

    tl.to('#textCentro', {
      x: 0,
      duration: 3
    });

    let tlf = gsap.timeline({
      scrollTrigger: {
        trigger: '.featureC',
        start: '-60% 70%',
        end: '50% center',
        scrub: true,
        markers: false,
        toggleActions: 'play reverse play reverse'
      }
    });

    tlf.to('.featureC', {
      x: 0,
      duration: 3
    });

    let tli = gsap.timeline({
      scrollTrigger: {
        trigger: '#imgCentro',
        start: '-70% center',
        end: '10% center',
        scrub: true,
        markers: false,
        toggleActions: 'play reverse play reverse'
      }
    });

    tli.to('#imgCentro', {
      x: 0,
      duration: 3
    });



  }, []);

  return (


    <div className="overflow-hidden py-24 sm:py-32 bg-gradient-to-b3">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="relative isolate px-5 pt-14 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">

            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="text-base font-semibold leading-7 text-indigo-600">Cuernavaca</h2>
                <Sparkles children={
                  <p className="mt-2 text-3xl font-bold tracking-tight text-purple-50 sm:text-4xl">Centro</p>
                }/>
                <div className="mt-6 text-lg leading-8 text-purple-50 translate-x-[-125%]" id='textCentro' >
                  <MapsTemplate campus={"centro"}/>
                </div>
              </div>
            </div>
            <div className='relative translate-x-[125%] w-[48rem] h-[40rem]' id='imgCentro'>
                            <img
                                src="https://lh3.googleusercontent.com/p/AF1QipMzFiHRuPskRTRl1amqwfZ-D9gqsnN6XMbn7DDz=s680-w680-h510"
                                alt="Product screenshot"
                                className="w-[48rem] h-[40rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                                width={2432}
                                height={1442}

                            />
                            <Link to={"/centro"} className='absolute top-3 left-3 w-24 h-12 bg-gray-900 text-gray-300 flex items-center justify-center rounded-lg font-semibold hover:border-solid hover:border-opacity-10 hover:border-2 cursor-pointer hover:text-white hover:font-md'>Saber más</Link>
                        </div>
            <div id='glowcircle' className='circlePosition w-[600px] h-[400px] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 rounded-[100%] fixed z-1  left-[20%] translate-x-[-50%] translate-y-[-50%] blur-[100px]'></div>
            <div
              className="absolute inset-x-0 -top-10 -top[50%] left-[0%] -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 10% 30.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div
              className="absolute inset-x-0 top-[50%] left-[-120%] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%)',
                }}
              />
            </div>

            {/* <div className='circlePosition w-[590px] h-[600px] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 rounded-[100%] fixed z-1 top-[50%] left-[20%] translate-x-[-50%] translate-y-[-50%] blur-[90px]'></div> */}
          </div>
        </div>
      </div>
    </div>
  )
}
