import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, StandaloneSearchBox, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const MapsTemplate = ({campus}) => {
  const [map, setMap] = useState(null);
  const [place, setPlace] = useState(null);

    const placeIds = {
        bugambilias:"ChIJE_0DExHfzYURlo2EGH35bJo",
        centro:"ChIJUzjadj7fzYUR3twB1gQYbmY",
        cuautla:"ChIJAw9ydRhvzoURrPC2R8E2Vhs",
        coyoacan:"ChIJHQqSBYL_0YURxVGlTz4HWVw"
    };

    const placeUrls = {
        bugambilias:"https://www.google.com.mx/maps/place/Larghetto+Academia+de+Arte+Musical/@18.9160694,-99.1960967,17.25z/data=!4m10!1m2!2m1!1slarghetto+bugambilias!3m6!1s0x85cddf111303fd13:0x9a6cf97d18848d96!8m2!3d18.9157991!4d-99.1951781!15sChVsYXJnaGV0dG8gYnVnYW1iaWxpYXOSAQxtdXNpY19zY2hvb2zgAQA!16s%2Fg%2F11j2cnrk8k?entry=ttu",
        centro:"https://www.google.com.mx/maps/place/Larghetto+Academia+de+Arte+Musical/@18.9232412,-99.2732495,14z/data=!4m10!1m2!2m1!1slarghetto!3m6!1s0x85cddf3e76da3853:0x666e1804d601dcde!8m2!3d18.9232459!4d-99.2382306!15sCglsYXJnaGV0dG-SAQxtdXNpY19zY2hvb2zgAQA!16s%2Fg%2F11fs0pbgvz?entry=ttu",
        cuautla:"https://www.google.com.mx/maps/place/Larghetto+Academia+de+Arte+Musical/@18.8196446,-98.9480497,17z/data=!4m10!1m2!2m1!1slarghetto!3m6!1s0x85ce6f1875720f03:0x1b5636c147b6f0ac!8m2!3d18.8198676!4d-98.9469362!15sCglsYXJnaGV0dG-SAQZzY2hvb2zgAQA!16s%2Fg%2F11b77q25vj?entry=ttu",
        coyoacan:"https://www.google.com.mx/maps/place/Larghetto+Coyoac%C3%A1n/@19.3438505,-99.1720515,14z/data=!4m10!1m2!2m1!1slarghetto!3m6!1s0x85d1ff8205920a1d:0x5c59073e4fa551c5!8m2!3d19.3438505!4d-99.1370326!15sCglsYXJnaGV0dG-SAQxtdXNpY19zY2hvb2zgAQA!16s%2Fg%2F11vx2s672_?entry=ttu"
    }

  useEffect(() => {
    const fetchPlaceDetails = async () => {
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: placeIds[campus], // Reemplaza con tu placeId
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPlace(place);
            setMap(map => {
              map.setCenter(place.geometry.location);
              return map;
            });
          } else {
            console.error('Error fetching place details:', status);
          }
        }
      );
    };

    if (map) {
      fetchPlaceDetails();
    }
  }, [map]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBMLO8yI_jY_qP-yhidAgoBM-iC-jNFTSg"
      libraries={['places']}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={place && place.geometry ? place.geometry.location : {lat:0, lng:0}} // Inicializa el centro del mapa
        zoom={19}
        onLoad={map => setMap(map)}
      >
        {place && (
          <Marker
          onClick={()=>{
            window.open(placeUrls[campus], '_blank');
          }}
            position={place.geometry.location}
            title={place.name}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapsTemplate;
