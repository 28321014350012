/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { Popover, Transition } from '@headlessui/react'
// import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { BiMenu, BiCross } from 'react-icons/bi'

const navigation = [
    { name: 'Oferta Educativa', href: '/' },
    { name: 'Instalaciones', href: '/' },
]
const navigationS = [
    { name: '¿Quiénes somos?', href: 'quienes' },
    { name: 'Misión y visión', href: 'vision' },
]

export default function AboutHero() {
    return (
        <div className="relative bg-gray-50 overflow-hidden" id='quienes'>
            <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 bg-gray-50 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                    <svg
                        className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-gray-50 transform translate-x-1/2"
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="none"
                        aria-hidden="true"
                    >
                        <polygon points="50,0 100,0 50,100 0,100" />
                    </svg>

                    <Popover>
                        <div className="relative pt-6 px-4 sm:px-6">
                            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                    <div className="flex items-center justify-between w-full md:w-auto">
                                        <Link to="/" className='bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 p-1 rounded-md'>
                                            {/* <p className="text-indigo-600 font-extrabold">Larghettoaaa</p> */}
                                            <img 
                                            className='w-20 h-auto p-1'
                                            src={require("../../../static/images/Logo Larghetto sin fondo.png")} alt="LogoAbout" />
                                        </Link>
                                        <div className="-mr-2 flex items-center md:hidden">
                                            <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Open main menu</span>
                                                <BiMenu className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:block md:ml-4 md:pr-4 md:space-x-3">
                                    {navigationS.map((item) => (
                                        <LinkS key={item.name} spy={true} smooth={true} offset={-300} duration={500} to={item.href} className="-m-1.5 p-1.5 cursor-pointer font-medium text-gray-500 hover:text-gray-900" >
                                            {item.name}
                                        </LinkS>
                                    ))}
                                    {navigation.map((item) => (
                                        <Link key={item.name} to={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                                            {item.name}
                                        </Link>
                                    ))}
                                </div>
                            </nav>
                        </div>

                        <Transition
                            as={Fragment}
                            enter="duration-150 ease-out"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="duration-100 ease-in"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Popover.Panel
                                focus
                                className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                            >
                                <div className="rounded-lg shadow-md bg-gray-50 ring-1 ring-black ring-opacity-5 overflow-hidden">
                                    <div className="px-5 pt-4 flex items-center justify-between">
                                        {/* <div className='font-bold text-violet-950'>
                                            Larghettoqqqqq
                                        </div> */}
                                        <Link to="/" className='bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 p-1 rounded-md'>
                                            {/* <p className="text-indigo-600 font-extrabold">Larghettoaaa</p> */}
                                            <img 
                                            className='w-20 h-auto p-1'
                                            src={require("../../../static/images/Logo Larghetto sin fondo.png")} alt="LogoAbout" />
                                        </Link>
                                        <div className="-mr-2">
                                            <Popover.Button className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close main menu</span>
                                                <BiCross className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="px-2 pt-2 pb-3 flex flex-col items-start">
                                        {navigationS.map((item) => (
                                            <LinkS key={item.name} spy={true} smooth={true} offset={-300} duration={500} to={item.href} className="-m-1.5 p-1.5 cursor-pointer font-medium text-gray-900 hover:text-gray-900" >
                                                {item.name}
                                            </LinkS>
                                        ))}
                                        {navigation.map((item) => (
                                            <Link key={item.name} to={item.href} className="font-medium text-gray-900 hover:text-gray-900">
                                                {item.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                                <span className="block xl:inline">¿Quienes Somos?</span>{' '}
                                <span className="block text-indigo-600 xl:inline">Larghetto</span>
                            </h1>
                            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                Somos una escuela de música comprometida con la excelencia académica, regida bajo la creencia de que nuestros alumnos, maestros y personal en general, son y serán siempre nuestra prioridad y por ello nos enfocamos en fomentar el aprendizaje y crecimiento personal de cada uno de ellos, en espacios idóneamente acondicionados, a través del respeto, humildad, empatía, y un profundo amor por la música, la cultura y el arte, entendiendo que el objetivo principal de esta empresa es el mejoramiento social a través del arte y sus artistas, es por ello que cada uno de los miembros del personal de Larghetto sabemos perfectamente que la nota perfecta es aquella que nutre el alma y no aquella que suena bajo la presión del perfeccionismo dañino e inalcanzable.
                            </p>
                            {/* <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                                <div className="rounded-md shadow">
                                    <a
                                        href="#"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                                    >
                                        Get started
                                    </a>
                                </div>
                                <div className="mt-3 sm:mt-0 sm:ml-3">
                                    <a
                                        href="#"
                                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                                    >
                                        Live demo
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                <img
                    className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
                    src="https://images.pexels.com/photos/2240765/pexels-photo-2240765.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt=""
                />
            </div>
        </div>
    )
}
