import { MusicalNoteIcon, GlobeAmericasIcon, PaintBrushIcon } from '@heroicons/react/20/solid'
import '../../../static/styles/GlowCircle.css'
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useEffect } from 'react';
import CampusCards from './CampusCards';
gsap.registerPlugin(ScrollTrigger)

export default function OfertaEducativa() {

    useEffect(() => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: '#textBuga',
                start: '-40% center',
                end: '100% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tl.to('#textBuga', {
            x: 0,
            duration: 3
        });

        let tlf = gsap.timeline({
            scrollTrigger: {
                trigger: '.featureB',
                start: '-60% 70%',
                end: '50% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tlf.to('.featureB', {
            x: 0,
            duration: 3
        });

        let tli = gsap.timeline({
            scrollTrigger: {
                trigger: '#imgBuga',
                start: '-70% center',
                end: '10% center',
                scrub: true,
                markers: false,
                toggleActions: 'play reverse play reverse'
            }
        });

        tli.to('#imgBuga', {
            x: 0,
            duration: 3
        });



    }, []);

    return (
        <div className="overflow-hidden py-24 sm:py-32 bg-black" id='oferta'>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="relative isolate px-6 pt-14 lg:px-8">
                    

                    <div className="">
                        <div className='text-[#f2f2f2] text-5xl mb-20'>Oferta Educativa</div>
                        <CampusCards/>
                        <div id='glowcircle' className='circlePosition w-[600px] h-[400px] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 rounded-[100%] fixed z-1  left-[20%] translate-x-[-50%] translate-y-[-50%] blur-[100px]'></div>
                        <div
                            className="absolute inset-x-0 -top-10 -top[50%] left-[0%] -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 10% 30.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                                }}
                            />
                        </div>
                        <div
                            className="absolute inset-x-0 top-[50%] left-[-120%] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                            aria-hidden="true"
                        >
                            <div
                                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                                style={{
                                    clipPath:
                                        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
