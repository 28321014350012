import React from 'react'
import AboutHero from './components/AboutHero'
import AboutThree from './components/AboutThree'
import MisionVision from './components/MisionVision'

export default function AboutUs() {
  return (
    <div className='bg-gray-50 pb-40'>
        <AboutHero/>
        <AboutThree/>
        <MisionVision/>
    </div>
  )
}
